import React, { useState } from 'react';
import Logo from './res/logo.png';
import Home from './views/home';
import AppleLogo from './res/apple_b.svg';
import AndroidLogo from './res/android.svg';
import './App.scss';

function App() {
  const [showDownload, setShowDownload] = useState(false);
  return (
    <div className="App">
      <header className="App-header">
        <div>
          <div className="yipei-logo">
            <img src={Logo} alt="yipei-logo" />
          </div>
          <div className="header-con">
            <div className="menu-con">
              <a href="/">
                <span>Home</span>
              </a>
            </div>
            <div className="menu-footer">

              <div onClick={() => setShowDownload(!showDownload)}>Download</div>

              <div className="download-btns" style={{ display: showDownload ? 'flex' : 'none' }}>
                <div className="download-btns-wrapper">
                  <div onClick={() => {
                    window.open("http://matchsoeasy.com/static/yipei.apk");
                    setShowDownload(false);
                  }}>
                    <img src={AndroidLogo} alt="androidLogo" />
                    Android
                  </div>
                  <div onClick={() => {
                    window.open("https://apps.apple.com/cn/app/%E6%98%93%E9%85%8D-%E5%85%B4%E8%B6%A3%E6%A0%87%E7%AD%BE/id1570639819");
                    setShowDownload(false);
                  }}>
                    <img src={AppleLogo} alt="applelogo" />
                    Apple
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div id="main">
        <Home />
        <div className="App-footer">
        </div>
      </div>
    </div>
  );
}

export default App;
