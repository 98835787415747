import React, { useState,Component } from 'react';
import One from '../../res/01.png';
import Two from '../../res/02.png';
import Three from '../../res/03.png';
import Four from '../../res/04.png';
import Five from '../../res/05.png';
import Six from '../../res/06.png';
import Seven from '../../res/07.png';
import Eight from '../../res/08.png';
import Logo from '../../res/logo.png';
import Ba from '../../res/ba.jpg';
import AppleLogo from '../../res/apple.svg';
import AndroidLogo from '../../res/android.svg';
import ewm from '../../res/ewm.jpg';
import ewm2 from '../../res/ewm2.png';
import videobg from '../../res/videobg.png';
import './index.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
SwiperCore.use([Navigation, Pagination]);
// 声明state类型
type StateType = {
  autoplay1: boolean,
  autoplay2: boolean,
  autoplay3: boolean,
  autoplay4: boolean,
  autoplay5: boolean,
  current: number,
  swiper: null
}
// 声明接口
interface Index {
  state: StateType,
}

class Index extends Component {
  constructor(props) {
      super(props);
      this.state = {
        autoplay1:false,
        autoplay2:false,
        autoplay3:false,
        autoplay4:false,
        autoplay5:false,
        current: 0,
        swiper:null
      }
  }
  
render(){ 
  return (
    <div className="yp-home">
      <div className="home-download">
        <div className="home-download-content">
          {/* <div className="yipei-logo">
            <img src={Logo} alt="yipei-logo" />
          </div> */}
          <div className="yipei-text">
            易配
          </div>
          <div className="desc" >
            不再众里寻他千百度
            <p>Match So Easy</p>
          </div>
          <div className="download-btns">
            <div onClick={() => {
              window.open("http://matchsoeasy.com/static/yipei.apk");
            }}>
              <img src={AndroidLogo} alt="androidLogo" />
              Download
            </div>
            <div onClick={() => {
              window.open("https://apps.apple.com/cn/app/%E6%98%93%E9%85%8D-%E5%85%B4%E8%B6%A3%E6%A0%87%E7%AD%BE/id1570639819");
            }}>
              <img src={AppleLogo} alt="applelogo" />
              Download
            </div>
          </div>
        </div>
        
        <div className="ewm">
          <div className="ewmcontent">
            <img className="ewmimg" src={ewm} alt="" />
            <div className="ewmtext">微信公众号</div>
          </div>
          <div className="ewmcontent">
            <img className="ewmimg" src={ewm2} alt="" />
            <div className="ewmtext">抖音公众号</div>
          </div>
        </div>
        <div className="info" >
          <img src={Ba} />
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010602009454">
            粤公网安备 44010602009454号
          </a>
          <a href="https://beian.miit.gov.cn/">
            备案号：粤ICP备2021064403号
          </a>
        </div>
      </div>
      <div className="home-content">
        <div className="img"><img src={One} alt="one" /></div>
        <div className="img"><img  src={Two} alt="Two" /></div>
        <div className="img"><img  src={Three} alt="Three" /></div>
        <div className="img"><img  src={Four} alt="Four" /></div>
        <div className="img"><img  src={Five} alt="Five" /></div>
        <div className="img"><img  src={Six} alt="Six" /></div>
        <div className="img"><img src={Seven} alt="Seven" /></div>
        <div className="img"><img  src={Eight} alt="Eight" /></div>
        <div className="video_content">
          <div className="videos">
            <img className="videobgs" src={videobg} alt="" ></img>
            <div className="swipers">
              <Swiper className="swiper"
                initialSlide={this.state.current}
                navigation
              >
                <SwiperSlide className="swiperslide">
                <video  src={'http://matchsoeasy.com/static/synthesize.mp4'} autoPlay={this.state.autoplay1} controls style={{ width: '100%', height: '100%', objectFit: 'fill', zIndex: 1 }} >
                </video>
                </SwiperSlide>
                <SwiperSlide className="swiperslide">
                <video  src={'http://matchsoeasy.com/static/yipei4.mp4'} autoPlay={this.state.autoplay2} controls style={{ width: '100%', height: '100%', objectFit: 'fill', zIndex: 1 }} >
                </video>
                </SwiperSlide>
                <SwiperSlide className="swiperslide">
                <video  src={'http://matchsoeasy.com/static/shareyourhobbies.mp4'} autoPlay={this.state.autoplay3} controls style={{ width: '100%', height: '100%', objectFit: 'fill', zIndex: 1 }} >
                </video>
                </SwiperSlide>
                <SwiperSlide className="swiperslide">
                <video  src={'http://matchsoeasy.com/static/pigteammates.mp4'} autoPlay={this.state.autoplay4} controls style={{ width: '100%', height: '100%', objectFit: 'fill', zIndex: 1 }} >
                </video>
                </SwiperSlide>
                <SwiperSlide className="swiperslide">
                <video  src={'http://matchsoeasy.com/static/gossipschool.mp4'} autoPlay={this.state.autoplay5} controls style={{ width: '100%', height: '100%', objectFit: 'fill', zIndex: 1 }} >
                </video>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
          
        </div>
        <div className="info" >
          <img src={Ba} />
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010602009454">
            粤公网安备 44010602009454号
          </a>
          <a href="https://beian.miit.gov.cn/">
            备案号：粤ICP备2021064403号
          </a>
        </div>
      </div>
    </div>
  );
}
}
export default Index;

